._1240 {
	max-width: 1240px;
}
._1320 {
	max-width: 1320px;
}

._1320,
._1240 {
	margin: 0 auto;
	@media (max-width: ($md1 + px)) {
		max-width: 970px;
	}
	@media (max-width: ($md2 + px)) {
		max-width: 750px;
	}
	@media (max-width: ($md3 + px)) {
		max-width: none;
		padding: 0 10px;
	}
}

.main-btn {
	width: 370px;
	height: 96px;
	box-shadow: 0 30px 60px rgba(173, 136, 110, 0.3), inset 0 -5px 5px rgba(255, 255, 255, 0.1),
		inset 0 0 20px rgba(255, 255, 255, 0.2);
	border-radius: 12px;
	background-image: linear-gradient(45deg, #ad886e 0%, #c39c81 100%);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: calc(22 / 15 * 100%);
	letter-spacing: 0.3px;
	transition: all 0.4s;
	position: relative;
	z-index: 3;
	color: #fff;
	&::after {
		position: absolute;
		width: 100%;
		height: 100%;
		content: "";
		z-index: -1;
		box-shadow: 0 30px 60px rgba(173, 136, 110, 0.4), inset 0 -5px 5px rgba(255, 255, 255, 0.1),
			inset 0 0 20px rgba(255, 255, 255, 0.2);
		background-image: linear-gradient(45deg, #ba9377 0%, #cfa688 100%);
		border-radius: 12px;
		opacity: 0;
		transition: all 0.4s;
	}
	&:hover::after {
		opacity: 1;
	}
	&:active {
		border: none;
		background-image: linear-gradient(45deg, #ad886e 0%, #c39c81 100%);
		box-shadow: none;
	}
	@media (max-width: ($md4 + px)) {
		width: 100%;
	}

	&_trans {
		width: 180px;
		height: 50px;
		font-size: 12px;
		background-image: none;
		box-shadow: none;
		border-radius: 6px;
		border: 1px solid rgb(#ffffff, 0.3);
		color: rgb(#ffffff, 0.4);
		&::after {
			box-shadow: 0 15px 30px rgba(173, 136, 110, 0.2), inset 0 -5px 5px rgba(255, 255, 255, 0.1),
				inset 0 0 20px rgba(255, 255, 255, 0.2);
			background-image: linear-gradient(45deg, #ba9377 0%, #cfa688 100%);
			border-radius: 6px;
			opacity: 0;
			transition: all 0.4s;
		}
		&:hover {
			color: #fff;
		}
	}
}

.main-title-section {
	font-size: 48px;
	line-height: calc(54 / 48 * 100%);
	font-weight: 600;
	color: #000;
	font-family: "Source Serif Pro", serif;
	text-align: center;
	margin: 0 auto;
	@media (max-width: ($md3 + px)) {
		font-size: 34px;
	}
	@media (max-width: ($md4 + px)) {
		font-size: 31px;
	}
}
.title-section {
	font-size: 36px;
	font-weight: 700;
	line-height: calc(46 / 36 * 100%);
	@media (max-width: ($md3 + px)) {
		font-size: 28px;
	}
	@media (max-width: ($md4 + px)) {
		font-size: 20px;
	}
}
.title-block {
	font-size: 24px;
	font-weight: 600;
	line-height: calc(32 / 24 * 100%);
	@media (max-width: ($md3 + px)) {
		font-size: 20px;
	}
}
.subtitle-section {
	font-size: 22px;
	font-weight: 400;
	line-height: calc(32 / 22 * 100%);
	@media (max-width: ($md3 + px)) {
		font-size: 18px;
	}
	@media (max-width: ($md4 + px)) {
		font-size: 16px;
	}
}
.subtitle-block {
	font-size: 18px;
	font-weight: 400;
	line-height: calc(28 / 18 * 100%);
	@media (max-width: ($md3 + px)) {
		font-size: 16px;
	}
}
.form-title {
	font-size: 36px;
	font-weight: 700;
	line-height: calc(46 / 36 * 100%);
	@media (max-width: ($md3 + px)) {
		font-size: 28px;
	}
	@media (max-width: ($md4 + px)) {
		font-size: 24px;
	}
}
.form-subtitle {
	font-size: 26px;
	font-weight: 400;
	line-height: calc(36 / 26 * 100%);
	@media (max-width: ($md3 + px)) {
		font-size: 22px;
	}
	@media (max-width: ($md4 + px)) {
		font-size: 18px;
	}
}

.form {
	// .form__container

	&__container {
		.main-btn {
			width: 100%;
		}
		margin-top: 36px;
	}

	// .form__in

	&__in {
	}
}
.form-block {
	// .form-block__input

	&__input {
		margin-bottom: 10px;
	}
}
.form-control {
	width: 100%;
	height: 96px;
	border-radius: 12px;
	border: 1px solid #f0f1f2;
	background-color: #fafbfc;
	font-size: 18px;
	padding-left: 46px;
	&::placeholder {
		color: rgb(#1e1e2a, 0.5);
	}
}
hr {
	background-color: #000000;
	opacity: 0.07;
	margin: 0;
}
.section-2,
.section-4,
.section-5 {
	picture img {
		background-color: #000;
		@media (max-width: ($md2 + px)) {
			background-color: transparent;
		}
		@media (max-width: (691px)) {
			border-radius: 15px;
		}
	}
}

.visible-hand {
	display: none;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
}
.bg {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		width: 1560px;
		height: 100%;
		border-radius: 60px;
		background-color: #f9fafb;
	}
}
.main_thank {
	.contacts__button {
		display: none;
	}
	.header-top {
		z-index: 7;
	}
}
